/*! 404 */

.error-page { position: relative; height: 320px; width: 320px; padding: 27px 0 0 0; border-radius: 50%; margin: 75px 0 30px;
        .error-page__character { width: 320px; height: 248px; transition: margin-top .05s; position: absolute; top: 50%; margin-top: -124px; left: 0;  }
        .error-page__sparkle { width: 320px; height: 320px; position: absolute; left: 0; top: 0; margin: 0; display: none; }
        &__title { font-size: 3em; font-weight: 600; text-transform: uppercase; }    
}

.left-arm { animation: scaredArmLeft 1.4s infinite; }
.right-arm { animation: scaredArmRight 1.4s infinite; }
.spark  { animation: oohGettingClose 1.4s infinite; transform-origin: center; }
.smoke { position: absolute; left: 0; top: -30px; display: none; }
.smoking .smoke { display: block; }

.mouth { transform-origin: center; }

.error-page:hover { 
    .error-page__character { animation: electrocution .15s linear infinite; margin-top: -134px;
        path,
        g,
        rect,
        ellipse,
        polygon,
        circle,
        .cls-1 { animation: electrocutionFlicker .2s linear infinite; }
    }
    .error-page__sparkle { display: block; animation: electrocutionSparkle 2s linear infinite;
        path,
        g { animation: electrocutionColour .5s linear infinite; }
    }
    .error-page__sparkle2 { display: block; animation: electrocutionSparkle 3s linear infinite reverse; }
    .left-arm { animation: none; transform: translateX(35px); }
    .right-arm { animation: none; transform: translateX(-35px); }  
    .spark,
    .shadow { display: none; } 
    &.smoking .smoke { display: none; }
    
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
    .eye { animation: eyeBlink 3s infinite; transform-origin: center; }
    .error-page:hover { 
        .left-leg { transform: rotate(45deg); transform-origin: center top; margin-top: 10px; }
        .right-leg { transform: rotate(-45deg); transform-origin: center top; margin-top: 10px; }
        .eye { transform: scale(1.2); }
        .mouth { transform: scale(3,3); }

    }
}

@keyframes scaredArmLeft {
    50% { transform: translateX(-30px); }
}
@keyframes scaredArmRight {
    50% { transform: translateX(30px); }
}
@keyframes oohGettingClose {
    0% { opacity: 1; transform: scale(1.1,1.1); }
    5% { opacity: 0; transform: scale(1,1); }
    10% { opacity: 1; transform: scale(1.1,1.1); }
    15% { opacity: 0; transform: scale(1,1); }
    85% { opacity: 0; transform: scale(1,1); }
    90% { opacity: 1; transform: scale(1.1,1.1); }
    95% { opacity: 0; transform: scale(1,1); }
    100% { opacity: 1; transform: scale(1.1,1.1); }    
}
@keyframes electrocution {
    0% { transform: rotate(-5deg); }
    50% { transform: rotate(-3deg); }
}
@keyframes electrocutionFlicker {
    50% { fill: #000; }
}
@keyframes electrocutionSparkle {
    100% { transform: rotate(360deg); }
}

@keyframes eyeBlink {
    80% { transform: scaleY(1); }
    90% { transform: scaleY(.1); }
    100% { transform: scaleY(1); }
}

@keyframes nervousMouth {
    50% { transform: translateX(20px); }
    70% { transform: translateX(-10px); }
    100% { transform: translateX(5px); }
}