.gallery-counter {
  position: absolute;
  right: 0;
  top: 0;
  padding: 7px;
  background-color: #FFF;
  text-shadow: 1px 1px 4px rgba(0,0,0,.5);
}

.gu-mirror .image-dimension,
.image-list .image-dimension {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #FFF;
  text-shadow: 1px 1px rgba(0,0,0,.4);
}

.image-list .reorder-list {
  width: 50%;
  float: left;
}

.image-list .reorder-list:nth-child(2n+1) { clear: left; }

.image-list .reorder-list-item {
  height: 270px;
  overflow: hidden;
}

.image-list .reorder-list-item__title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ededed;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  margin: 0;
  z-index: 1;
  transition: bottom .5s ease .1s;
}

.image-list .reorder-list:hover .reorder-list-item__title {
  bottom: 50px;
}

.image-list .temporaryList {
  width: 50%;
  float: left;
  padding: 20px;
}

.image-list .temporaryList-item {
  transition: all .25s;
  border: 1px solid #ccc;
  border-top: none;
  position: relative;
  height: 270px;
  overflow: hidden;
}

.image-list .temporaryList-item .image-holder { overflow: hidden; }

.image-list .temporaryList-item .image-holder img { width: 100%; height: auto; display: block; transition: all .5s ease; transform-origin: center;}
.image-list .temporaryList-item  .image-holder:hover img { transform: scale(1.1,1.1) rotate(3deg); }

.image-list .temporaryList:nth-child(2n+1) { clear: left; }

.image-list .thumbnail-edit-hover { display: block; position: absolute; top: 0; right: 0; left: 0; bottom: 0; z-index: 10; overflow: hidden; transition: background .5s ease;
    .flaticon-tool { position: absolute; left: 50%; top: -60px; transform: translate(60%,-50%); font-size: 2em; color: $primary-colour; background: #fff; height: 60px; width: 60px; border-radius: 50%; text-align: center; line-height: 60px; border: 1px solid #ccc; transition: top .5s ease .2s; cursor: pointer; }
    .flaticon-edit { position: absolute; left: 50%; top: -60px; transform: translate(-160%,-50%); font-size: 2em; color: $primary-colour; background: #fff; height: 60px; width: 60px; border-radius: 50%; text-align: center; line-height: 60px; border: 1px solid #ccc; transition: top .5s ease; cursor: pointer; }
    .thumbnail-drag { position: absolute; left: 50%; top: -60px; transform: translate(-50%,-50%); font-size: 2em; color: $primary-colour; background: #fff; height: 60px; width: 60px; border-radius: 50%; text-align: center; line-height: 60px; border: 1px solid #ccc; transition: top .5s ease .1s;
        svg { height: 20px; width: 20px;
            path { fill: $primary-colour; }
        }
    }

    &:hover {
        span { top: 32.5%; }
    }
}

/* New Tag for when images are uploaded - it is displayed before the gallery is saved again */

.new-item {
  position: relative;
}

.new-item:before {
  content: "new";
  width: auto;
  height: 20px;
  position: absolute;
  top: 30px;
  left: 30px;
  text-transform: uppercase;
  z-index: 100;
  color: #FFF;
  padding: 0 10px;
  line-height: 18px;
  border: 1px solid #FFF;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, .25);
  z-index: 9;
}


/* Progress bar */

.reorder-list-item__image-holder .progressBar{
    position: absolute;
    width: 60%;
    height: 15px;
    top: 32.5%;
    left: 50%;
    margin-left: -30%;
    -webkit-transform:translateY(-50%);
    z-index: 9;
}

.reorder-list-item__image-holder .progressBar[value] {
  -webkit-appearance: none;
   appearance: none;
}

.reorder-list-item__image-holder .progressBar[value]::-webkit-progress-bar {
  background-color:#ededed;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

.reorder-list-item__image-holder .progressBar[value]::-webkit-progress-value {
  background-color: #f96332;
  border-radius: 10px;
}

.reorder-list-item__image-holder .progressBar-green[value]::-webkit-progress-value {
  background-color: #5cb860;
  transition: background-color 1s;
}

.reorder-list-item__image-holder .progressBar-green{
  opacity:0;
  transition: opacity 3s;
}


/* This rearranges the position of CTAS when a new image is added to the gallery and fails upload */

.new-item-failed .thumbnail-edit-hover .flaticon-tool,
.new-item-failed .thumbnail-edit-hover .thumbnail-drag {
  left: 32.5%;
}

/* ------------------------------------------------------------------------------- */


/* Image Upload Feedback Messages */

.reorder-list-item__upload-feeback {
  bottom: 40px;
  text-align: center;
  height: auto;
  padding: 15px;
  color:#FFF;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 1.4;
}

.reorder-list-item__upload-feeback-fail {
  background-color: #da3124;
}

.reorder-list-item__upload-feeback-success {
  background-color: #5cb860;
}
