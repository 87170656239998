.lister-panel { background: #fff; max-width: 980px; margin: 0 auto 30px; }
.lister-panel.margin-bottom { margin-bottom: 15px; } 

.none-yet { padding: 80px; text-align: center; color: #999; font-size: 1.6em; margin: 0; font-style: italic; }

.num-results-holder { padding: 30px 20px; position: relative; border-bottom: 1px solid #ededed;  }
.num-results-holder .pagination { position: absolute; right: 20px; top: 50%; margin-top: -17px; }
.num-results-holder p { margin: 0; display: inline-block; }

.num-items-per-page { display: inline-block; vertical-align: middle; 
    label,
    .select-wrapper { display: inline-block; vertical-align: middle; }
    .select-wrapper { margin: 0 5px 0 30px; }
}

.lister-total-results { margin: 0 15px 0 0; text-transform: uppercase; color: #666; }

.list-table,
.gu-mirror { font-size: 1.2em; width: 100%; color: #666;

    th { text-transform: uppercase; background: #f5f5f5; border-bottom: 1px solid #e3e3e3; border-left: 1px solid #e3e3e3; padding: 10px 20px; font-weight: 400; transition: background .25s ease-in; 
        .glyph-icon { float: right; }
        .flaticon-arrows-8,
        .flaticon-arrows-6,
		.flaticon-arrows-7 { font-size: 80%; position: relative; top: 1px; }
		.flaticon-arrows-6,
		.flaticon-arrows-7 { font-size: 70%; position: relative; top: 3px; }
    }
    
    th.sortable:hover { cursor: pointer; background: #e6e6e6; }
    th a { text-decoration: none; color: inherit; display: block; }
    
    td { padding: 20px; border-bottom: 1px solid #e3e3e3; border-left: 1px solid #e3e3e3; }
    tr th:first-child,
    tr td:first-child { border-left: none; }
    
    .action { width: 50px; padding: 0; overflow: hidden;
        a { display: block; text-align: center; font-size: 150%; color: $primary-colour; padding: 20px; transition: background .25s ease-in, transform .15s ease-in; }
        
        .action-icon { border: none; background: none; display: block; text-align: center; font-size: 150%; color: $primary-colour; padding: 20px; transition: background .25s ease-in, transform .15s ease-in;  }
        
        .action-icon:hover,
        a:hover { background: #f5f5f5; transform: scale(1.2,1.2); }
    }


}

.gu-mirror { 
    tr { width: 100%; display: block; }
    td { display: inline-block; width: 20%; }
}

.column-section { width: 160px; }
.column-sub-section { width: 250px; }
.column-page-type { width: 140px; }
.column-page-title { width: auto; }

.lister-actions { text-align: center; }

.list-table.translations th { width:33.33%; }
.list-table.translations input[type="text"] { width:100%; }

.list-table.courses th {  }
.list-table.courses th.column-title { width: 250px; }
.list-table.courses th.column-start-date { width: 135px; }
.list-table.courses th.column-category { width: 135px; }
.list-table.courses th.role { width: 100px; }

.lister--empty { padding: 50px; text-align: center; color: #666; width: 420px; margin: 0 auto 40px;
    h2 { font-size: 2em; color: #666; margin: 0 0 10px; font-weight: 600; text-shadow: -1px -1px 0 rgba(255,255,255,1); }
    svg { width: 180px; height: 266px; margin: 0 0 30px; } 
    p  { font-size: 1.4em; margin: 0 0 20px; } 
}

.lister--empty-decor { position: relative; height: 320px; width: 320px; padding: 27px 0 0 0; background: #fff; border-radius: 50%; margin: 0 0 30px;
    .lister-panel & { background: #ededed;
        svg { transform: translateX(20px); }
    }
}
#left-eye,
#right-eye,
#left-eye2,
#right-eye2 { border-radius: 50%; display: block; position: absolute; width: 28px; height: 20px; top: 104px; left: 50%; }                
#left-eye { margin-left: -34px; }
#left-eye2 { top: 103px; margin-left: -38px; }
#right-eye { margin-left: 12px; }
#right-eye2 { top: 88px; margin-left: 12px; width: 60px; height: 60px; }
#left-pupil,
#left-pupil2,
#right-pupil,
#right-pupil2 { width: 6px; height: 6px; background: #000; border-radius: 50%; display: block; position: absolute; left: 50%; top: 50%; margin: -3px 0 0 -3px; animation: eyeBlink 3s infinite; transform-origin: center; }
#right-pupil2 { width: 24px; height: 24px; margin: -12px 0 0 -12px; }
.lister-mouth { opacity: 1; transform: scaleY(-1); transform-origin: center top; }
.add-hover .lister-mouth { opacity: 1; transform: scaleY(1); }

@media screen and (-webkit-min-device-pixel-ratio:0) {
.lister-mouth { transition: transform .25s ease; }
.add-hover .right-leg,
.add-hover .right-foot { animation: jump .5s infinite; }
.add-hover .left-leg,
.add-hover .left-foot { animation: jump .5s infinite .25s; }
.search-arm { animation: search 2s infinite; transform-origin: right top; }
}

.search-term { display: table; padding: 3px 10px; background: #666; color: #fff; text-shadow: -1px -1px 0 rgba(0,0,0,.3); margin: 5px auto 25px; border-radius: 3px; }

@keyframes jump {
  0% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
}

@keyframes search {
  0% { transform: rotate(0); }
  50% { transform: rotate(-10deg); }
  100% { transform: rotate(0); }
}

.lister-panel { position: relative; }
.list-ajax-spinner { pointer-events: none; position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%); z-index: 100; }
.spinner-background { position: absolute; left: 0; top: 0; bottom: 0; right: 0; background: rgba(255,255,255,.9); z-index: 40; pointer-events: none; opacity: 0; transition: opacity 1s ease; transition-delay: .5s; }
.list-spinner .spinner-background { opacity: 1; transition-delay: 0s; }