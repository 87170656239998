/*! Shop Dashboard */



.feature-stats-list { margin: 0 -5px; padding: 0; list-style: none; 
	li { width: 25%; float: left; padding: 0 5px; font-size: 1em; text-align: left; }
	a { display: block; background-color: #27729f; color: #fff; text-decoration: none; }
	
	li:nth-child(2) a { background-color: #479e93; }
	li:nth-child(3) a { background-color: #d25a51; }
	li:nth-child(4) a { background-color: #d88826; }
}

.feature-stat-number { font-size: 3.2em; font-weight: 700; display: block; padding: 20px; border-bottom: 1px solid rgba(255,255,255,.1); }

.feature-stat-title { font-size: 1.3em; text-transform: uppercase; display: block; padding: 10px 20px; }

.feature-stats + .dashboard-links { margin-bottom: 10px; }

.orders { background-position: 10px 10px; background-repeat: no-repeat; }

.orders--pending { background-image: url(../img/shop/icon-orders-pending.png); }
.orders--for-dispatch { background-image: url(../img/shop/icon-orders-for-dispatch.png); }
.orders--total { background-image: url(../img/shop/icon-orders.png); }
.orders--revenue { background-image: url(../img/shop/icon-revenue.png); }

.shop-dashboard-heading { font-size: 1.3em; margin: 0 0 10px; }

.current-activity { margin: 0 -5px; }
.current-activity-stats-col, 
.current-activity-graph-col { padding: 0 5px; width: 25%; float: left; text-align: left; }
.current-activity-graph-col { width: 75%; }

.current-activity-stat { background: #fff;
	h2 { padding: 15px 20px; border-bottom: 1px solid #ededed; margin: 0; }
	p { padding: 15px 20px 15px; color: $primary-colour; font-weight: 700; font-size: 3.2em; }
}

.current-activity-graph { background: #fff; padding: 15px 20px; height: 392px; }

.current-graph-container { width: 100%; height: 337px; background: #fff; }
.performance-graph-container { height: 480px; width: 100%; }

.graph-placeholder { width: 100%; height: 100%; font-size: 14px; line-height: 1.2em; }

.last-12-months { background: #fff; text-align: left; margin: 0 0 10px;
	.column-content { margin: 0; }
	:first-child .column-content { padding: 15px 5px 15px 20px; }
	:last-child .column-content { padding: 15px 20px 15px 5px; }
}

.revenue-table { font-size: 1.3em; width: 100%; background: #ededed;
	td, th { padding: 10px 15px; }
	tbody td { border-top: 1px dotted #ccc; }
	tr td:last-child,
	tr th:last-child { text-align: right; color: $primary-colour; }
}

.percentage-stats-list { margin: 0 -5px; padding: 0; list-style: none; 
	li { width: 25%; float: left; padding: 0 5px; font-size: 1em; text-align: left; }
	.percentage-stat { display: block; background-color: #27729f; color: #fff; text-decoration: none; height: 295px; position: relative; }
	
	li:nth-child(2) .percentage-stat { background-color: #479e93; }
	li:nth-child(3) .percentage-stat { background-color: #d25a51; }
	li:nth-child(4) .percentage-stat { background-color: #d88826; }
}


.percentage-stat-number { font-size: 3.2em; font-weight: 700; display: block; text-align: center; position: absolute; width: 100%; text-align: center; top: 50%; left: 0; z-index: 2; margin: -5px 0 0; }

.percentage-stat-title { font-size: 1.3em; text-transform: uppercase; display: block; padding: 15px 20px; text-align: center; border-bottom: 1px solid rgba(255,255,255,.1);  }

.doughnut-graph-container { width: 198px; height: 198px; position: absolute; left: 50%; bottom: 30px; margin: 0 0 0 -99px; }