/*! Tags */

.tags-holder { position: absolute; left: 0; right: 0; top: 83px; bottom: 96px; overflow-y: auto; }

.col-selected-tags { padding: 20px; width: 35%; background: #f5f5f5; }
.col-choose-tags { padding: 20px; border-left: 1px solid #ededed; width: 65%;
    h2, h3 {
     .btn--sm { float: right; }
     }
     .form-input-border-b { padding-left: 20px; padding-right: 20px; margin-left: -20px; margin-right: -20px; }
}

.tags-selection { display: flex; min-height: 100%; }

.selected-tags-heading { font-size: 1.3em; color: $primary-colour; text-transform: uppercase; }
.tags-list { list-style: none; margin: 0 -20px 20px; padding: 0 20px 15px; border-bottom: 1px solid #ededed; }
.tag { background: #fff; border: 1px solid #ccc; padding: 4px 30px 4px 10px; display: inline-block; border-radius: 3px; margin: 0 5px 5px 0; position: relative; overflow: hidden;
    a { text-decoration: none; color: inherit; }
    button { background: none; border: none; padding: 0; }
}
.tag-selected { color: #219653; }
.draggable-tag:hover { border-color: #999; cursor: move; }
.remove-tag,
.delete-tag button { position: absolute; right: 0; top: 0; border-left: 1px solid #ccc; font-size: 6px; text-align: center; line-height: 10px; bottom: 0; width: 20px; color: #999; background: #ededed;
    .glyph-icon { position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%); }
}
.add-tag { position: absolute; right: 0; top: 0; border-left: 1px solid #ccc; font-size: 6px; text-align: center; line-height: 10px; bottom: 0; width: 20px; color: #999; background: #ededed;
    .glyph-icon { position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%); }
}
.more-tag { position: absolute; right: 0; top: 0; border-left: 1px solid #ccc; font-size: 6px; text-align: center; line-height: 10px; bottom: 0; width: 20px; color: #999; background: #ededed;
    .glyph-icon { position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%); }
}
.remove-tag:hover { color: #900; }

.select-tag-heading { font-size: 1.3em; font-weight: 300; padding: 0 20px; margin: 0 -20px 15px; color: $primary-colour; text-transform: uppercase; }

.tag-edit-btn { border: none; background: none; font-size: 14px; color: $primary-colour; padding: 4px 10px; margin-left: 5px; float: right; }

.select-tags-list { list-style: none; margin: 0 -20px 15px; padding: 0 20px 15px; border-bottom: 1px solid #ededed; }

.select-tag-sub-heading { font-size: 1.3em; font-weight: 300; padding: 10px 0 10px 20px; margin: 0 -20px 15px; color: $primary-colour; background: #fff; text-transform: uppercase;
    .btn--sm,
    .tag-edit-btn { margin-right: 20px; }
}

.viewing-tag-category { background: #f6f6f6; padding: 20px 20px 5px; margin: 0 0 15px;
    .select-tags-list { margin-bottom: 0; padding-bottom: 0; border: none; }
    .select-tag-sub-heading { margin: 0 -40px 15px; }
}

.centre-tag-actions { text-align: center; }

.tag input { border: none; padding: 6px 0; width: 138px; }

.tags-list-in-page {
  padding: 20px;
  margin: 0;
}
