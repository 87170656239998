/*! Header */

.suite-header { height: 50px; background: $primary-colour; width: 100%; text-transform: uppercase; position: fixed; left: 0; top: 0; height: 100%; width: 150px; z-index: 100;
    &--with-languages { width: 150px; }
}

.suite-title { font-size: 1.4em; margin: 0; line-height: 1; background: #dc582d; height: 100px; 
    a { display: block; }
}

.header-user-details { text-align: center; color: #fff; padding: 40px 0 20px;
	p { margin: 0; line-height: 1; }
	.glyph-icon { vertical-align: middle; font-size: 400%; display: block; margin: 0 auto 10px; }	
}

.btn-header { background: #dc582d; padding: 10px 20px; font-size: .75em; color: #fff; text-decoration: none; vertical-align: middle; display: block; text-align: center; }

.header-user-details { position: absolute; left: 0; bottom: 0; width: 100%;
    p { margin: 0 0 20px; }
}

.user-details ul { margin: 0; list-style: none; padding: 0 10px;
    li { font-size: 1.1em; }
	a { color: #fff; text-transform: uppercase; background: #dc582d; text-decoration: none; display: block; transition: background .25s ease-in; text-align: center; height: 40px; line-height: 40px; }
	a:hover { background: rgba(0,0,0,.1); }

 }
 
 .language-selector { list-style: none; margin: 0; padding: 0 10px 0 0; 
    ul { list-style: none; margin: 0; padding: 0; }
	li { float: left; margin: 0 0 0 5px; padding: 8px 0; }
	a { display: block; height: 34px; line-height: 34px; border-radius: 17px; text-transform: uppercase; color: #fff; text-decoration: none; width: 100px; text-align: center; border: 1px solid transparent; transition: border-color .25s ease-in; }
	li.on a,
	a:hover { border-color: #fff; }
}