/*! Dashboard */

.dashboard-banner { height: 280px; background: url(../../../SwimWales/Website/img/dashboard-banner.jpg) center center; background-size: cover; background-position:center top; margin: 0; }
.dashboard-holder { margin: 0 auto; position: relative; text-align: center; padding: 0; }

.dashboard-minor-links { background: $primary-colour; padding: 11px 20px;

	ul { margin: 0; text-align: right; padding: 0; }
	li { display: inline-block; margin: 0 0 0 15px; border-left: 1px solid #fff; padding-left: 15px; line-height: 1; }
	li:first-child { border-left: none; }
	a { color: #fff; text-transform: uppercase; text-decoration: none; }

}

.dashboard-header { background: #fff; padding: 20px 0 30px; }

.dashboard-heading { font-size: 3.6em; color: #666; margin: 0 284px 10px; padding: 40px 0 0; line-height: 1.1; font-weight: 400; }
.dashboard-subheading { color: $primary-colour; font-size: 1.8em; max-width: 700px; margin: 0 auto 20px; }

.dashboard-links { list-style: none; margin: 0 0 20px; padding: 0; display: flex; justify-content: left; flex-flow: wrap; border-top: 1px solid #ededed;

	li { width: 20%; margin: 0; padding: 0; border-right: 1px solid #ededed; border-bottom: 1px solid #ededed; display: table; text-align: center; opacity: 0; transform: translate(0,80px); }
	a { display: table-cell; background: #fff; vertical-align: middle; padding: 0 0 70px; color: $primary-colour; font-size: 1.2em; text-decoration: none; text-transform: uppercase; position: relative;
        svg { width: 40px; height: 40px; transition: transform 1s ease; transform-origin: center;
            path { fill: #666; }
        }
        &::before { content: ''; display: block; position: absolute; left: 0; top: 0; height: 100%; width: 0; background: rgba(0,0,0,.02); transition: width .5s ease; }
    }

}
.dashboard-links a:hover {
    &::before { width: 100%; }
}
.cms-icon { padding: 70px 0 10px; background: #fff; }

@keyframes fadeAndMove {
    0% { opacity: 0; transform: translate(0,80px); }
    100% { opacity: 1; transform: translate(0,0); }
}

.dashboard-links li:nth-child(1) { animation: fadeAndMove .3s ease .05s; animation-fill-mode: forwards; }
.dashboard-links li:nth-child(2) { animation: fadeAndMove .3s ease .1s; animation-fill-mode: forwards; }
.dashboard-links li:nth-child(3) { animation: fadeAndMove .3s ease .15s; animation-fill-mode: forwards; }
.dashboard-links li:nth-child(4) { animation: fadeAndMove .3s ease .2s; animation-fill-mode: forwards; }
.dashboard-links li:nth-child(5) { animation: fadeAndMove .3s ease .25s; animation-fill-mode: forwards; }
.dashboard-links li:nth-child(6) { animation: fadeAndMove .3s ease .3s; animation-fill-mode: forwards; }
.dashboard-links li:nth-child(7) { animation: fadeAndMove .3s ease .35s; animation-fill-mode: forwards; }
.dashboard-links li:nth-child(8) { animation: fadeAndMove .3s ease .4s; animation-fill-mode: forwards; }
.dashboard-links li:nth-child(9) { animation: fadeAndMove .3s ease .45s; animation-fill-mode: forwards; }
.dashboard-links li:nth-child(10) { animation: fadeAndMove .3s ease .5s; animation-fill-mode: forwards; }
.dashboard-links li:nth-child(11) { animation: fadeAndMove .3s ease .55s; animation-fill-mode: forwards; }
.dashboard-links li:nth-child(12) { animation: fadeAndMove .3s ease .6s; animation-fill-mode: forwards; }
.dashboard-links li:nth-child(13) { animation: fadeAndMove .3s ease .65s; animation-fill-mode: forwards; }
.dashboard-links li:nth-child(14) { animation: fadeAndMove .3s ease .7s; animation-fill-mode: forwards; }
.dashboard-links li:nth-child(15) { animation: fadeAndMove .3s ease .75s; animation-fill-mode: forwards; }
.dashboard-links li:nth-child(16) { animation: fadeAndMove .3s ease .8s; animation-fill-mode: forwards; }
.dashboard-links li:nth-child(17) { animation: fadeAndMove .3s ease .85s; animation-fill-mode: forwards; }
.dashboard-links li:nth-child(18) { animation: fadeAndMove .3s ease .9s; animation-fill-mode: forwards; }
.dashboard-links li:nth-child(19) { animation: fadeAndMove .3s ease .95s; animation-fill-mode: forwards; }
.dashboard-links li:nth-child(20) { animation: fadeAndMove .3s ease 1s; animation-fill-mode: forwards; }

.dashboard-banner { animation: bannerMove 25s linear 0s infinite alternate; background-position: top center; }

@keyframes bannerMove {
    0%   {background-size: 100% auto;}
    100%  {background-size: 120% auto;}
}
