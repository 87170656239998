
.calentim-open {
    overflow: hidden;
}

.calentim-no-anim {
	transition: all 0s ease !important;
}

.calentim-popup {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
}

.calentim-box-arrow-top {
    width: 22px;
    height: 22px;
    z-index: 12;
    margin-left: 0;
    position: absolute;
    top: 0px;
    &:after, &:before {
        bottom: calc(100% - 6px);
        left: 27px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
        border-width: 10px;
        margin-left: -10px;
    }
    &:before {
        border-color: rgba(184, 184, 184, 0);
        border-bottom-color: $calentim-border-color;
        border-width: 11px;
        margin-left: -11px;
    }
}

.calentim-box-arrow-bottom {
    width: 22px;
    height: 22px;
    z-index: 12;
    margin-left: 0;
    position: absolute;
    bottom: 0px;
    &:after, &:before {
        bottom: -15px;
        left: 27px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #ffffff;
        border-width: 11px;
        margin-left: -11px;
    }
    &:before {
        border-color: rgba(184, 184, 184, 0);
        border-top-color: $calentim-border-color;
        border-width: 10px;
        margin-left: -10px;
    }
}

.calentim-no-scroll::-webkit-scrollbar {display:none;}
.calentim-no-scroll::-moz-scrollbar {display:none;}
.calentim-no-scroll::-o-scrollbar {display:none;}
.calentim-no-scroll::-google-ms-scrollbar {display:none;}
.calentim-no-scroll::-khtml-scrollbar {display:none;}
