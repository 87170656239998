.suite-sidepanel { border-bottom: 1px solid #111; padding: 0 70px; position: absolute; top: 0; left: 0; width: 100%; background: #333; box-shadow: 0 5px 5px rgba(0,0,0,.3); z-index: 10; 
                 h1 { font-size: 2em; margin: 0; height: 50px; line-height: 50px; color: #fff; font-weight: 400; }
}
.sidepanel-status { position: absolute; right: 170px; height: 24px; line-height: 24px; padding: 0 10px; border-radius: 3px; color: #fff; margin: 0; top: 50%; margin: -12px 0 0; text-transform: uppercase; font-weight: 600;
                  &--archived { background: $archived; }
                  &--pending { background: $pending; }
                  &--published { background: $published; }
}
.sidepanel-note { font-size: 50%; text-transform: uppercase; padding: 0 10px 0 0; margin: 0 10px 0 0; border-right: 1px solid #999; position: relative; top: -2px; color: #999; }
.close-sidepanel { position: absolute; left: 0; top: 0; display: block; width: 50px; height: 50px; margin: 0; color: #fff; line-height: 50px; text-align: center; font-size: 1em; background: #333; border-right: 1px solid rgba(255,255,255,.1); }

.sidepanel-frame__holder { transition: all .25s ease; }

.sidepanel-frame { position: absolute; left: 0; top: 50px; bottom: 0; right: 0; background: #fff; transition: all .25s ease; overflow: hidden;
                 iframe { width: 100%; height: 100%; position: absolute; overflow: auto; border: none; transition: all .25s ease; z-index: 2; }

                 &--mobile { background: #333 url(http://api.thumbr.it/whitenoise-361x370.png?background=333333ff&noise=000000&density=100&opacity=16); padding: 40px; min-height: 747px; 
                             .sidepanel-frame__holder { width: 375px; height: 667px; margin: 0 auto; box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); }  
                             iframe { width: 375px; background: #fff; height: 667px }
                             .device-icon--mobile { top: 100px; left: 100px; opacity: 1; }
                 }

                 &--mobile-landscape { background: #333 url(http://api.thumbr.it/whitenoise-361x370.png?background=333333ff&noise=000000&density=100&opacity=16); padding: 40px; min-height: 747px;
                                       .sidepanel-frame__holder { width: 667px; height: 375px; margin: 0 auto; box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); }  
                                       iframe { width: 667px; background: #fff; height: 375px }
                                       .device-icon--mobile { top: 100px; left: 100px; transform: rotate(90deg); transform-origin: center; opacity: 1; }
                 }

                 &--tablet { background: #333 url(http://api.thumbr.it/whitenoise-361x370.png?background=333333ff&noise=000000&density=100&opacity=16); padding: 40px; min-height: 1104px;
                             .sidepanel-frame__holder { width: 768px; height: 1024px; margin: 0 auto; box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); }  
                             iframe { width: 768px; background: #fff; height: 1024px }
                             .device-icon--tablet { top: 100px; left: 100px; opacity: 1; }
                 }

                 &--tablet-landscape { background: #333 url(http://api.thumbr.it/whitenoise-361x370.png?background=333333ff&noise=000000&density=100&opacity=16); padding: 40px; min-height: 848px;
                                       .sidepanel-frame__holder { width: 1024px; height: 768px; margin: 0 auto; box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); }  
                                       iframe { width: 1024px; background: #fff; height: 768px }
                                       .device-icon--tablet { top: 100px; left: 100px; transform: rotate(90deg); transform-origin: center; opacity: 1; }
                 }
}

.sidepanel-preview { position: absolute; right: 3px; top: 0; list-style: none; padding: 0;
                  li { display: inline-block; border-left: 1px solid #666; width: 50px; margin: 0 0 0 -2px; }
                  a { display: inline-block; height: 50px; width: 50px; padding: 15px; 
                      &.active,
                      &.active-rotate { background: rgba(255,255,255,.1); }
                      &.active-rotate { 
                          svg { transform: rotate(90deg);}
                      }
                  }
                  svg { width: 20px; height: 20px; transform-origin: center; transition: transform .25s ease; }
}

.device-icon { position: absolute; left: 100px; top: 100px; transition: all .25s ease; opacity: 0;
               svg { height: 400px; width: 400px; opacity: .1; }
               path { fill: #000;}
}