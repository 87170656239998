.toggle-tags-filter { text-transform: uppercase; background: #ededed; color: #333 !important; height: 30px; line-height: 30px; padding: 0 20px; border-radius: 15px; display: inline-block !important; cursor: pointer; border: 1px solid #ccc; position: relative; z-index: 20; font-size: 1.1em !important;
    span { font-size: 6px; margin-right: 10px; position: relative; top: -2px; }
}
.toggle-tags-filter.active {
    span:before { transform: rotate(180deg); }
}
.filter-tags { margin: 15px 0 -20px; padding: 0; text-align: center; position: relative;
    &:before { border-top: 1px solid #ddd; content: ''; display: block; height: 1px; position: absolute; left: 0; top: 21px; right: 0; }
    button { background: #fff; border-radius: 3px; border: 1px solid #ccc; padding: 5px 27px 5px 10px; margin: 0 3px 5px; position: relative; font-size: 1.1em; }
}

.filter-tag-categories { display: inline-block; padding: 15px 10px; vertical-align: top; position: relative; margin-left: -1ch;
    &:not(:first-of-type)::after { display: block; content: ''; position: absolute; left: 0; top: 15px; bottom: 15px; width: 1px; background: #ddd; }
    h3 { font-size: 1.1em; text-transform: uppercase; margin: 0 0 5px; }
}
.filter-available-tags { padding: 0; 
    h2 { margin-bottom: 15px !important; }
}
.current-filter-tags { padding: 0 5px 15px; 
    h2 { margin-bottom: 15px !important; }
}
/*
.current-filter-tags { padding: 0 0 10px; position: fixed; width: 980px; left: 50%; top: 20px; box-shadow: 0 0 20px rgba(0,0,0,.5); transform: translateX(-50%); background: #fff; margin-left: 75px; border-radius: 10px;
    h2 { margin-bottom: 15px !important; }
}*/

.filter-remove-tag span { font-size: 6px; margin-left: 10px; transform: translateY(-1px); }

.form-inline .tags-filter { background: #fff; border-bottom: 1px solid #ddd; margin-top: -8px; 
    h2 { padding: 3px 10px; border-bottom: 1px solid #e6e6e6; display: block; margin: 0; border-top: 1px solid #e6e6e6; background: #f6f6f6; text-transform: uppercase; font-size: 1.1em; color: #333; }
}

.filter-scrollable-area { white-space: nowrap; overflow-x: auto; padding: 0 20px 10px; }
.filter-scrollable-area--categories { padding-bottom: 0; }


.form-inline .tags-filter { margin-top: 0; }
.tags-filter { position: fixed; right: 0; top: -280px; left: 150px; height: 280px; background: #fff; z-index: 19; transition: top .25s ease; }
.current-filter-tags { position: absolute; bottom: 0; left: 0; right: 0; height: 103px; background: #f6f6f6; }
.tags-filter.show { top: 0; }

.no-tag-filter { color: #666; font-style: italic; }


.filter-tags .filter-tag-categories button { outline: none; }
.filter-tags .filter-tag-categories button.active { border-color: #ccc; background: #ededed }
.filter-tags .filter-tag-categories button.active .more-tag { transform: scaleY(-1); }