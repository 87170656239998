/*! Order List */

.order-panel { background: #f6f6f6; border-bottom: 1px solid #ededed; padding: 20px; }

.reorder-list { width: 25%; float: left; padding: 20px; }
.reorder-list:nth-child(4n+1) { clear: left; }

.reorder-list-item { background: #fff; transition: all .25s; border: 1px solid #ccc; border-top: none; position: relative;
    &__image-holder { overflow: hidden; height: 100%;
        img { width: 100%; height: 100%; object-fit: cover; overflow: hidden; display: block; transition: all .5s ease; transform-origin: center;  }
    }
    &__title { text-align: center; margin: 0; border-top: 1px solid #ededed; color: #666; display: block; padding: 20px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
    //&:hover img { transform: scale(1.1,1.1) rotate(3deg); }
    &__tags { background-color: #FFF; list-style: none; width: 100%; padding:0 20px; height: 50px; line-height: 50px; position: absolute; bottom: 0; left: 0; z-index: 2;
      li { display: inline-block; width: 23.5%; background-color: #ededed; border-radius: 20px; height: 30px; line-height:28px; text-align: center; border: 1px solid #ededed;}
      li:not(:last-child) {margin-right: 3px;}
      .disabled {background-color: #FFF; color: #CCC;}
    }
}
.thumbnail-edit-hover { display: block; position: absolute; top: 0; right: 0; left: 0; bottom: 0; z-index: 10; overflow: hidden; transition: background .5s ease;
    .flaticon-tool { position: absolute; left: 50%; top: -60px; transform: translate(10%,-50%); font-size: 2em; color: $primary-colour; background: #fff; height: 60px; width: 60px; border-radius: 50%; text-align: center; line-height: 60px; border: 1px solid #ccc; transition: top .5s ease .1s; cursor: pointer; }
    .flaticon-edit { position: absolute; left: 50%; top: -60px; transform: translate(-110%,-50%); font-size: 2em; color: $primary-colour; background: #fff; height: 60px; width: 60px; border-radius: 50%; text-align: center; line-height: 60px; border: 1px solid #ccc; transition: top .5s ease; cursor: pointer; }
    .thumbnail-drag { position: absolute; left: 50%; top: -60px; transform: translate(10%,-50%); font-size: 2em; color: $primary-colour; background: #fff; height: 60px; width: 60px; border-radius: 50%; text-align: center; line-height: 60px; border: 1px solid #ccc; transition: top .5s ease .1s; cursor: move;
        svg { height: 20px; width: 20px;
            path { fill: $primary-colour; }
        }
    }

    &:hover {
        span { top: 40%; }
    }
}


.page-header--order { max-width: 800px; padding: 0 10px;
    .page-header-cta { margin: 4px 10px 0 0; }
}

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  // outline: 1px dotted #ccc; box-shadow: 0 0 10px rgba(0,0,0,.3);
  font-size: 1em;
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  // outline: 1px dotted #ccc; box-shadow: 0 0 30px rgba(0,0,0,.3); user-select: none !important;
}
.gu-transit {
    outline: 1px dotted $primary-colour; transition: transform 0.2s; z-index: 0; background: #fff; position: relative; z-index: 2;
    img,
    a { opacity: .2; }
}

.gu-mirror .drag-hide { display: none !important; }

.js-lister-content-wrapper .reorder-list-item__image-holder { max-height: 140px; height: 100%; }

.gallery-images { position: relative; }
.gallery-images .loader-holder { position: absolute; left: 0; right: 0; top: 82px; bottom: 82px; background: #f6f6f6;  z-index: 10; display: none; align-items: center; justify-content: center; }
.spinner-showing.gallery-images .loader-holder { display: flex; }
