
/******* VENUES LISTER *******/

#venue-map-view-container .standard-form-input {
  margin: 15px 0;
}

#venue-map-view-container .standard-form-input span.show-all {
  float: right;
  margin-top: -2px;
}

#venue-map-view-container a{
  color: #333;
}







/******* ADD A VENUE *******/

/* VENUE DIRECTIONS */

.venue-directions-panel {
  border-radius: 0;
  & .transit-transports-option {
    padding: 0;
    margin: 20px 0 0 0;
  }
}

.results-research-transit-transports .empty--text-message {
  text-align: center;
  background: #f5f5f5;
  padding: 40px;
  font-size: 1.15em;
}

.types-transit-transports-container label.show-label {
  width: 5%;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  font-size: 1.15em;
}

.types-transit-transports-container .types-transit-transports {
  width: auto;
  display: inline;
  margin-left: 40px;
  margin-bottom: 15px;
}

.types-transit-transports-container .input-group {
  display: inline-block;
}

.types-transit-transports-container .input-group p {
  display: inline;
}

.types-transit-transports-container .radius-transit-transports {
  display: inline-block;
  margin-left: 20px;
  margin-bottom: 20px;
  font-size: 1.15em;
  & label {
    display: inline;
  }
  & input {
    margin-left: 10px;
  }
}

.spinner {
    max-width: 60px;
    height: auto;
    margin: 0 auto;
    display: none;
  }


.types-transit-transports-container #transit-transports {
  width: 100%;
  padding: 20px 40px;
  background: #f5f5f5;
  float: left;
  display:none;
}

.types-transit-transports-container .transport-type {
  width: 100%;
  float: left;
  margin-bottom: 20px;
  & legend {
    width: 100%;
    text-transform: uppercase;
    color: #666;
    display: block;
    text-align: left;
    border-bottom: 1px solid #ededed;
    padding-bottom: 30px;
    margin-bottom: 20px;
  }
  & p {
    width: 31.5%;
    margin-bottom: 10px;
    display: inline-block;
    height: auto;
    & label {
      height: auto;
      vertical-align: top;
    }
  }
  & .no-type-results {
    padding: 40px 0;
    font-size: 1.15em;
    color: #333;
  }
}

/* VENUE OPENING TIMES */

.js-opening-time-container select {
  font-size: .85em;
}

.js-opening-time-container .list-usage-table__day {
  width: 20%;
}

.js-opening-time-container .list-usage-table__openingTimes {
  width: 59%;
  & ul {
    list-style: none;
    padding: 0;
    & li {
      display: inline;
      & select {
        padding-right: 36px;
      }
    }
  }
}

.js-opening-time-container .list-usage-table__closeAllDay {
  width:16%;
  & p {
    text-align: center;
    margin: -22px 0 0 5px;
    & .checkbox [type="checkbox"]:not(:checked)+label {
      padding: 0;
    }
    & .checkbox [type="checkbox"]:checked+label {
      padding: 0;
    }
  }
}

.js-opening-time-container .list-usage-table__delete {
  width:5%;
}

.venue-empty-day {
  display:none;
}

.js-add-another-day {
  font-size: 1.15em;
}


/* VENUE DOWNLOADS */

.venue-downloads .standard-form-input .inline-input label {
  width: 23.5%;
}

.download-wrapper {
  border-bottom: 1px solid #ededed;
  padding-top: 20px;
   & > legend{
     font-size: 1.2em;
     color: #666;
     text-transform: uppercase;
     display: block;
     position: relative;

     margin-bottom: 10px;
     padding-left: 210px;
   }
}


/* VENUE FACILITIES */
.list-table-facilities input {
  width: 70%;
}
