
/* Advanced Checkbox Hack */
body { -webkit-animation: bugfix infinite 1s; min-height: 100%; transition: transform .3s ease; }
html { height: 100%; }

@-webkit-keyframes bugfix {
  from { padding: 0; }
  to {  padding: 0; }
}


.suite-nav { height: 50px; position: relative; margin: 10px; }

.menu-trigger-holder { margin: 0; line-height: 50px; background: rgba(0,0,0,.2); }
.menu-trigger { display: block; height: 50px; position: relative; transition: $fade-transition; padding: 0 0 0 45px; color: #fff; text-decoration: none; }
.menu-trigger:hover { background: rgba(0,0,0,.1); }

.menu-bar { position: absolute; left: 15px; top: 50%; margin: -1p 0 0;  }

.menu-bar,
.menu-bar::after,
.menu-bar::before { display: block; background: #fff; border-radius: 1px; width: 20px; height: 2px; content: ''; transition: all .25s ease; }

.menu-bar { transform: translateY(-7px); }
.menu-bar::after { position: absolute; transform: translateY(7px); }
.menu-bar::before { position: absolute; transform: translateY(14px); }

.navigation-expanded {
    .menu-bar::after { opacity: 0; }    
    .menu-bar { transform: translateY(0) rotate(45deg); transform-origin: center; }
    .menu-bar::before { position: absolute; transform: translateY(0) rotate(-90deg); transform-origin: center; }
}

.dropdown-nav { position: fixed; background: #fff; top: 0; left: 150px; width: 300px; padding: 70px 0 50px; display: block; box-shadow: 1px 0 1px rgba(0,0,0,.2); z-index: 100; height: 100%; overflow: hidden; transform: scaleX(0); transition: transform .15s ease; transform-origin: left top; 
    .navigation-expanded & { transform: scaleX(1); }
}
.dropdown-nav ul { margin: 0 auto; padding: 0; list-style: none; transform: translateX(-300px); transition: transform .3s ease .15s; border-top: 1px solid #ededed;
	li { margin: 0; }
	a { color: $primary-colour; text-decoration: none; text-transform: uppercase; display: block; padding: 10px 40px; transition: all .25s ease; border-bottom: 1px solid #ededed; }
	a:hover { background: #f6f6f6; padding: 10px 40px 10px 50px; }
	li.on a { background: $primary-colour; color: #fff; }
    .navigation-expanded & { transform: translateX(0); }
}

.suite-quicklinks-holder { position: absolute; background: #333; left: 0; width: 100%; top: 0; height: 50px; }
.suite-quicklinks { margin: 0; padding: 0; list-style: none; float: left;
	li { display: table; border-right: 1px solid rgba(255,255,255,.1); float: left; line-height: 1; }
	a { color: #fff; text-decoration: none; height: 50px; display: table-cell; vertical-align: middle; padding: 0 20px; transition: $fade-transition; }
	a:hover { background: rgba(255,255,255,.1); }
}